import React from 'react'
import Layout from '../components/Layout'
import Form from '../components/Form/verify'
import View from '../components/View'
import { navigate, globalHistory } from '@reach/router'
import { handleRegisterConfirm } from '../utils/auth'
import { parseQuery } from '../utils/query'
import { isBrowser } from '../utils/misc'

class Verify extends React.Component {
  state = {
    username: ``,
    code: ``,
    errorMessage: ``,
  }

  componentDidMount() {
    var query = this.state.query
    if (globalHistory.location.search) {
      query = parseQuery(globalHistory.location.search)
      if (query.email) {
        this.setState({
          username: query.email,
        })
      }
    }
  }
  handleUpdate(event) {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleSubmit(event) {
    event.preventDefault()
    var updateMessage = function (text) {
      var nevent = {
        target: {
          name: 'errorMessage',
          value: text,
        },
      }
      this.handleUpdate(nevent)
    }.bind(this)

    handleRegisterConfirm(this.state, function (err, user) {
      if (err) {
        updateMessage(err.message)
      } else {
        navigate('/profile')
      }
    })
  }

  render() {
    if (isBrowser) {
      return (
        <Layout>
          <View title="Verify Your Account">
            <Form
              username={this.state.username}
              handleUpdate={(e) => this.handleUpdate(e)}
              handleSubmit={(e) => this.handleSubmit(e)}
            />
            <label>{this.state.errorMessage}</label>
          </View>
        </Layout>
      )
    } else {
      return null
    }
  }
}

export default Verify
